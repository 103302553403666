<template>
  <section class="limit-his-land">
    <section class="hero">
      <div class="hero-container">
        <h1>Borner son terrain,<br> une sécurité juridique</h1>
      </div>
    </section>
    <div class="hero-img">
      <img src="@/assets/img/pages/limit-his-land-1.jpg" alt="Borner son terrain" />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Cette opération, qui vise à déterminer les limites légales d’un terrain à construire, est
          obligatoire en lotissement. Elle préserve le futur propriétaire d’éventuels litiges
          administratifs et lui évite de futurs contentieux avec son voisinage. <br>
          Explication.
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Beaucoup de candidats à l’acquisition d’un habitat individuel vont consulter en
              mairie, au centre des impôts ou sur internet le plan cadastral d’une commune afn d’y
              vérifer la superfcie <router-link to="/terrains/je-recherche-un-terrain-constructible">d’un terrain à vendre</router-link>. Cette
              démarche, pour utile qu’elle soit, est rarement la bonne pour qui souhaite obtenir
              des informations fables sur le réel potentiel d’un bien foncier.
            </p>
            <p>
              Peu de gens le savent, mais les données fournies par ce document administratif sont
              dénuées de valeur « juridique » et n’ont qu’une portée indicative. La fnalité du
              registre cadastral est en eet fscale : les renseignements chirés qu’il renferme
              servent notamment de base de calcul aux diérentes taxes locales immobilières prélevées
              sur les ménages. Il est donc illusoire, voire périlleux, de se fonder sur ces
              références pour estimer la valeur d’une parcelle à bâtir et déterminer si son prix de
              vente correspond à sa surface réelle. Conséquence : faute d’indication fable et
              précise sur les limites séparatives d’une parcelle par rapport à un terrain contigu,
              il ne garantit, pas à son possesseur un droit de propriété, au sens strict du terme.
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/limit-his-land-2.jpg"
              alt="Déterminer les limites légales d'un terrain à constuire"
            />
          </template>
        </card-type-img>
      </div>
      <div class="article-demarcation fit-content">
        <h2>Le bornage doit figurer dans la promesse ou l’acte de vente</h2>
        <div class="article-demarcation-img">
          <img
            src="@/assets/img/pages/limit-his-land-3.jpg"
            alt="Maîtriser les coûts de construction"
          />
        </div>
        <div class="article-demarcation-text">
          <p>
            Pour éviter tout litige avec un futur voisin et/ou se prémunir contre un risque de
            contentieux administratif lié au non-respect d’un POS (plan d’occupation des sols) ou un
            plan local d’urbanisme (PLU), qui imposent notamment des distances réglementaires entre
            deux constructions, seul un bornage fait foi et constitue, à ce titre, un gage juridique
            dont peut se prévaloir l’occupant d’un fond privé en cas de contestation. Signalons que
            cette opération est impérativement réalisée par un expert-géomètre et qu’elle est
            obligatoire pour n’importe quel terrain à bâtir dès lors qu’il est commercialisé dans le
            cadre d’un lotissement (elle est facultative en revanche sur une parcelle isolée situé
            en secteur dius).
          </p>
          <p>
            Dans le cas d’un bien « loti », le bornage est à la charge de l’aménageur qui doit en
            donner le résultat dans la promesse et/ou le contrat de vente (
            <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000031210397" target="_blank">Article L115-4 du Code de l’Urbanisme</a>) sous la forme
            « d’un descriptif du terrain ». Si ces renseignements obligatoires et irréfutables –
            validés par un procès-verbal d’abornement établi par l’expert-géomètre – ne sont pas
            mentionnées dans lesdits documents relatifs à la transaction, l’acquéreur a la possibilité
            d’exercer un droit de recours en nullité de la vente.
          </p>
          <p>
            Rappelons qu’en lotissement, les terrains pour maisons neuves doivent être viabilisés
            avant d’être vendus : cela signife qu’ils sont raccordés aux réseaux et à
            l’assainissement. Le lotisseur est également soumis à une autre règle, celle de
            s’assurer que chaque lot est délimité avec exactitude : d’où son obligation de faire de
            réaliser un bornage périmétrique en bonne et due forme.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'limit-his-land',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.limit-his-land
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-demarcation
      margin: 4.5rem auto 0 auto
      h2
        text-align: center
        color: $primary
      &-img
        margin-top: 4.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%;
    max-width: 950px;
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-demarcation
        margin: 3rem auto 0 auto
        &-img
          margin-top: 2.5rem
        &-text
          margin-top: 1.5rem
</style>
